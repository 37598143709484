@import url('./__logo/header__logo.css');
@import url('./__auth-link/header__auth-link.css');
@import url('./__wrapper/header__wrapper.css');
@import url('./__user/header__user.css');
@import url('./__logout/header__logout.css');

.header {
  min-height: 120px;
  /*Если подгонять под ПЭПЭ, то тут min-height: 117px;*/
  font-family: 'Inter', Arial, sans-serif;
  display: flex;
  align-items: center;
  /*border-bottom: 1px solid rgba(84, 84, 84, 0.7);*/
  /*Перевести в RGBA, чтобы учесть opacity*/
  flex-shrink: 0;
  /*Стики футер*/
  justify-content: space-between;
}

.header::before {
  content: '';
  width: 100%;
  height: 1px;
  opacity: 0.7;
  background: #545454;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  /*Центруем. Студенты знают про транслейт*/
  bottom: 0;
}
/*Лучшее решение здесь -- можно лучше*/

@media screen and (max-width: 480px) {
  .header {
    min-height: 85px;
  }
  .header::before {
    width: calc(100% + 40px);
  }
}
