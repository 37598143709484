.popup__caption {
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  margin: 0;
  /*
    позиционируем абсолютно. В задании сказано только про максимальные размеры изображения.
  */
}
