.popup__error {
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  opacity: 0;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  transition: opacity 0.3s;
}
