@import url('./__title/auth-form__title.css');
@import url('./__form/auth-form__form.css');
@import url('./__input/auth-form__input.css');
@import url('./__textfield/auth-form__textfield.css');
@import url('./__button/auth-form__button.css');
@import url('./__text/auth-form__text.css');
@import url('./__link/auth-form__link.css');

.auth-form {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}