.profile__title {
  font-size: 42px;
  line-height: 48px;
  /*Подгон под ПЭПЭ - без него 56px*/
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /*Либо с точками из макета, либо этот набор свойств для эллипсиса текста в можно лучше*/
  grid-area: title;
  margin: 0;
}

@media screen and (max-width: 740px) {
  .profile__title {
    font-size: 32px;
    line-height: 38px;
  }
}

@media screen and (max-width: 568px) {
  .profile__title {
    font-size: 27px;
    line-height: 33px;
    /*По макету 56px. Проявите лоялити к студенту*/
  }
}

@media screen and (max-width: 480px) {
  .profile__title {
    min-width: 201px;
    /*
      Это по макету. Вообще я считаю тут должен быть отступ до иконки, но фрейм у заголовка кривой.
      Проявите лоялити к студенту
    */
  }
}
