.profile__info {
  display: grid;
  grid-template-areas: "title button"
  "description description";
  grid-template-columns: minmax(auto, 295px) auto;
  /*Можно не гридить*/
  grid-gap: 9px 17px;
  /*Подгон под ПЭПЭ, без него - 16px*/
}

@media screen and (max-width: 740px) {
  .profile__info {
    grid-template-columns: minmax(auto, 228px) auto;
    grid-gap: 9px 5px;
  }
}

@media screen and (max-width: 568px) {
  .profile__info {
    grid-template-columns: minmax(auto, 195px) auto;
  }
}

@media screen and (max-width: 480px) {
  .profile__info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 26px 0 33px 0;
  }
}
