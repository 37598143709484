.profile__edit-button {
  width: 24px;
  height: 24px;
  background: transparent url('../../../images/edit-icon.svg') center no-repeat;
  /*Шортхэнды пора бы знать, хотя бы в можно лучше*/
  background-size: 10px 10px;
  border: 1px solid #fff;
  grid-area: button;
  align-self: center;
  /*Можно марджином тут выровнять под ПЭПЭ, но тут оставил AS.*/
  cursor: pointer;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  /*Транзишн пора бы уже знать*/
  padding: 0;
  margin: 0;
}

.profile__edit-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 480px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    background-size: 8px 8px;
  }
}
