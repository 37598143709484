.card__delete-button {
  width: 18px;
  height: 18px;
  background: transparent url('../../../images/delete-icon.svg') center no-repeat;
  /*Потом это будет из модификатора*/
  background-size: 18px 18px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  padding: 0;
  margin: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.card__delete-button:hover {
  opacity: 0.6;
}
