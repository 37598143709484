@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url("./fonts/Inter-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url("./fonts/Inter-Black.woff2") format("woff2");
}
