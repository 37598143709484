.popup__content {
  max-width: 430px;
  width: 100%;
  min-height: 330px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 34px 36px;
}

@media screen and (max-width: 568px) {
  .popup__content {
    width: 100%;
    max-width: calc(100% - 80px);
    margin-top: 40px;
    padding: 30px 20px;
  }
}
