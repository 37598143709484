.header__logo {
  width: 142px;
  height: 33px;
  object-fit: contain;
}

@media screen and (max-width: 480px) {
  .header__logo {
    width: 104px;
    height: 24px;
    margin: 0 0 0 7px;
  }
}
