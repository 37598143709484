.auth-form__button {
  width: 358px;
  height: 50px;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.auth-form__button:hover {
  opacity: .85;
}